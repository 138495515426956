/**
 * ⚠️ WARNING ⚠️
 * This file is only for high level strings and enums.
 */

import { OpsOrMedicalAtenttionFilterOptions } from "library/components/ApplicationList/ApplicationList.mbx";
import {
  BusinessAdminRole,
  BusinessBeneficiaryState,
  BusinessCheckCheckType,
  BusinessGroupsConfigState,
  BusinessStakeholderCheckCheckType,
  BusinessState,
  BusinessPreApprovedStatesType,
  CancelReason,
  CaseEventAdmissionSource,
  CaseEventCategory,
  CaseEventClassOptions,
  CaseEventDischargeReason,
  CaseEventFinalAmountsState,
  CaseEventMemberStage,
  CaseEventQuoteLetterLetterType,
  CaseEventStage,
  CaseEventState,
  CheckState,
  ClaimRulingChecksDeclinedReason,
  CoInsuranceConfigFactor,
  ComplianceAlertAnnotation,
  ComplianceAlertCategory,
  ComplianceReportType,
  CovidVaccinationStatus,
  CptProviderClassification,
  CustomerPaymentFlowType,
  DoctorLeadInfoState,
  DoctorLeadLostReason,
  DoctorLeadState,
  DoctorLeaveReason,
  DoctorPriorityLevel,
  DocumentCategory,
  ElectronicVoucherElectronicVoucherOperationState,
  ElectronicVoucherVoucherType,
  EmrType,
  GenderType,
  GrapheneScheduledConsultClassification,
  GrapheneScheduledConsultClassificationDeprecated,
  GroupName,
  HealthPlanAmendmentAmendmentCategory,
  HealthPlanAmendmentReviewResult,
  HealthPlanApplicationItemCheckCheckType,
  HealthPlanApplicationItemCheckState,
  HealthPlanApplicationItemRejectionReason,
  HealthPlanApplicationItemRelationship,
  HealthPlanApplicationItemState,
  HealthPlanApplicationSource,
  HealthPlanApplicationState,
  HealthPlanRelationship,
  HealthPlanState,
  HealthPlanSubscriptionType,
  IncomePaymentFlow,
  InsuredAmountCents,
  InvoiceCounterpartyType,
  InvoiceState,
  InvoicesQueryServiceType,
  MaternityWaitingPeriodMonths,
  MedicalRecommendation,
  MemberExclusionState,
  MemberGender,
  NoteType,
  OnDemandConsultClassificationType,
  PayInMethods,
  PaymentMethodsPaymentMethodType,
  PayoutTiers,
  PersonCategory,
  PharmacyOrderState,
  ProcedureName,
  ProductFamilies,
  ProfilingAilment,
  ProfilingDataCheckState,
  ProfilingDataState,
  ProfilingHospitalizationType,
  ProfilingMentalDiagnosis,
  Relationship,
  RulingCheckState,
  SatInvoiceUseType,
  SatOperationTypeStatus,
  SatRegime,
  SatVoucherType,
  ScheduledConsultState,
  ServiceCoveragePayoutMode,
  ServiceCoveragePayoutState,
  ServiceCoverageRulingState,
  ServiceProcessState,
  ServiceRulingDifficulty,
  ServiceType,
  ServiceTypeSpecification,
  SpecializationType,
  SponsorType,
  StakeHolderState,
  SubscriptionType,
  TaskState,
  TransactionPaymentGatewayResult,
  TransactionTransactionType,
  ConsultCancellationReason,
  ServiceTransactionState,
  CollectiveRelationshipType,
  CptPriceSource,
  SatPaymentForm,
  SatPaymentMethod,
  SatCancellationServiceCancellationState,
  CaseEventSummaryCategory,
  CoverageTag,
  ScheduledApprovalCallStatus,
  CaseEventFinalLetterLetterType,
  BusinessConfigProposalState,
  CeleryTaskStatusType,
  BusinessConfigProposalIssuanceType,
  BusinessBeneficiaryCategory,
  PreviousProductType,
  BusinessBeneficiaryConfigProposalState,
  MedicalTeamRole,
  ProfilingHospitalizationSideLocationType,
  ProfilingVisionConditionType,
  ProfilingGastrointestinalConditionType,
  CieCatalogCategory,
  ServiceCancelReason,
  CaseEventRejectionLetterLetterType,
  CaseEventPaymentMethodSelected,
  MedicalFeeFinalLetterLetterType,
  MedicalFeeRejectionLetterLetterType,
  BusinessPaymentStatus,
  BusinessPaymentMedicalService,
  ServiceEscalationReason,
  BusinessOrdinaryPeriodDays,
} from "types/schema";

/**
 * Common states
 */
enum States {
  PartiallyApproved = "Aprobado parcial",
  Approved = "Aprobado",
  ApprovedForPayment = "Aprobado para cobro",
  Cancelled = "Cancelado",
  Closed = "Cerrado",
  Draft = "Borrador",
  Error = "Error",
  InReview = "En revisión",
  MoreInfoNeeded = "Información adicional",
  PaymentFailed = "Cobro fallido",
  Pending = "Pendiente",
  Rejected = "Rechazado",
  Requested = "Solicitado",
  ReviewNeeded = "Revisión pendiente",
  Subscribed = "Solicitud aprobada",
  InSitu = "In Situ",
  WaitingSubscriber = "Esperando resp. cliente",
  WaitingConditions = "Periodo de espera COVID",
  ToBeRenewed = "En espera de renovación",
  Audited = "Auditado",
  NotAudited = "No auditado",
}

/**
 * @todo - Create an enum that groups content type objects added to invoice in the backend
 */
export enum InvoiceContentTypes {
  Ip = "inpersonconsult",
  Hp = "healthplan",
  On = "ondemandconsult",
  Se = "services",
  Ha = "healthplanamendment",
  Ot = "ondemandconsulttimelog",
  Po = "pharmacyorder",
}

const translations = {
  globals: {
    null: "Nulo",
    nullState: "Sin estado",
    undefined: "Indefinido",
    undefinedPlan: "Plan indefinido",
    notApplicable: "No aplica",
  },
  errors: {
    empty: "Error sin mensaje",
    generic: "Ocurrió un error",
    undefined: "Error indefinido",
    networkError: "Error de red",
    file: {
      notFound: "No se encontró el archivo",
    },
  },
  load: {
    loading: "Cargando",
    loadingError: "Error al cargar",
    sending: "Enviando",
    sendingError: "Error al enviar",
  },
  gender: {
    "": "Indefinido",
    U: "Sin especificar",
    F: "Femenino",
    M: "Masculino",
  },
  country: {
    "": "Indefinido",
    A_: "Sin especificar",
    MX: "México",
    OT: "Otro",
  },
  countryCode: {
    MEXICO: "México",
    OTHER: "Otro",
  },
  nationality: {
    "": "Indefinido",
    A_: "Sin especificar",
    MX: "Mexicana",
    OT: "Otra",
  },
  subscriptionType: {
    MN: "Mensual",
    YR: "Anual",
    QR: "Trimestral",
    BN: "Bianual",
  },
  genderType: {
    [GenderType.Female]: "Mujer",
    [GenderType.Male]: "Hombre",
    [GenderType.Unknown]: "Otro",
  },
  healthPlanAmendmentReviewResult: {
    [HealthPlanAmendmentReviewResult.Pn]: States.Pending,
    [HealthPlanAmendmentReviewResult.Ap]: States.Approved,
    [HealthPlanAmendmentReviewResult.Dn]: States.Rejected,
  },
  HealthPlanRelationship: {
    [HealthPlanRelationship.Wf]: "Esposa",
    [HealthPlanRelationship.Hs]: "Esposo",
    [HealthPlanRelationship.Dg]: "Hija",
    [HealthPlanRelationship.Sn]: "Hijo",
    [HealthPlanRelationship.Mt]: "Madre",
    [HealthPlanRelationship.Ft]: "Padre",
    [HealthPlanRelationship.Gp]: "Abuel@",
    [HealthPlanRelationship.Sb]: "Herman@",
    [HealthPlanRelationship.Np]: "Sobrin@",
    [HealthPlanRelationship.Gc]: "Niet@",
    [HealthPlanRelationship.Sl]: "Self",
    [HealthPlanRelationship.Ot]: "Otro",
  },
  emergencyContactRelationship: {
    [Relationship.Wife]: "Esposa",
    [Relationship.Husband]: "Esposo",
    [Relationship.Daughter]: "Hija",
    [Relationship.Son]: "Hijo",
    [Relationship.Mother]: "Madre",
    [Relationship.Father]: "Padre",
    [Relationship.Grandparent]: "Abuel@",
    [Relationship.Sibling]: "Herman@",
    [Relationship.Nephew]: "Sobrin@",
    [Relationship.Grandchild]: "Niet@",
    [Relationship.Self]: "Self",
    [Relationship.Other]: "Otro",
  },
  MemberGender: {
    [MemberGender.F]: "Femenino",
    [MemberGender.M]: "Masculino",
  },
  documentCategory: {
    [DocumentCategory.AmendmentComplementary]: "Complemento de endoso",
    [DocumentCategory.Curp]: "CURP",
    [DocumentCategory.Identity]: "Identificación (Frente)",
    [DocumentCategory.IdentityBackside]: "Identificación (Reverso)",
    [DocumentCategory.ProofOfAddress]: "Comprobante de domicilio",
    [DocumentCategory.Rfc]: "RFC",
    [DocumentCategory.Signature]: "Firma",
  },
  payoutTiers: {
    [PayoutTiers.TierOne]: "Tier 1",
    [PayoutTiers.TierTwo]: "Tier 2",
    [PayoutTiers.Unlimited]: "Ilimitado",
  },
  cancelReason: {
    // B2C
    [CancelReason.Costly]: "[B2C] No puedo absorber el costo actualmente",
    [CancelReason.OutOfArea]: "[B2C] Me fui a vivir lejos, fuera del área de cobertura",
    [CancelReason.JobInsurance]: "[B2C] Me dieron otro seguro en el trabajo",
    [CancelReason.WantsToChange]: "[B2C] Quiero cambiar de seguro, encontré una mejor opción",
    [CancelReason.BadCustomerService]: "[B2C] Quiero cambiar de seguro, tuve una mala experiencia con Sofía",
    [CancelReason.NotCoveredPreexistence]: "[B2C] No me cubrieron un padecimiento",
    // B2C/B2B
    [CancelReason.Errors]: "[B2C/B2B] Reemisión (por error)",
    [CancelReason.Reissued]: "[B2C/B2B] Reemisión (por cambio de coberturas)",
    [CancelReason.Debt]: "[B2C/B2B] Deuda",
    [CancelReason.Fraud]: "[B2C/B2B] Fraude",
    // B2B
    [CancelReason.EmployeeChurn]: "[B2B] La persona ya no trabajará en la empresa",
    [CancelReason.EmployeePrefersOtherInsurance]: "[B2B] La empresa ofrece opciones de seguros a sus colaborador@s, el/la colaborador@ optó por otro.",
    [CancelReason.NotSure]: "[B2B] Otras razones",
    [CancelReason.BusinessTooCostly]: "[B2B] No podemos absorber el costo de está prestación por ahora",
    [CancelReason.BusinessBelowExpService]: "[B2B] El servicio no cumplió con nuestras expectativas",
    [CancelReason.BusinessBelowExpProduct]: "[B2B] Nos cambiaremos a otro seguro que hace más sentido para nosotros (e.g., por precio, por coberturas)",
    // Wont be used but to make Ts happy
    [CancelReason.ProblemNotSolved]: "Problemas no resueltos",
    [CancelReason.DidNotLike]: "No me gustó",
    [CancelReason.NotUse]: "No lo uso",
    [CancelReason.BusinessCancellation]: "Cancelación SMB",
    [CancelReason.BusinessMigration]: "Migración SMB",
  },
  planType: {
    INDIVIDUAL: "Individual",
    FAMILIAR: "Familiar",
  },

  /**
   * @deprecated
   */
  statesGeneric: {
    AC: "Activo",
    AP: "Aprobado",
    AV: "Disponible",
    CL: "Cerrado",
    CM: "Completado",
    CN: "Cancelado",
    DR: "Borrador",
    EX: "Expirado",
    IP: "En progreso",
    MR: "Información requerida",
    NT: "No pagado",
    PD: "Pagado",
    RF: "Reembolsado",
    RJ: "Rechazado",
    SB: "Enviado",
    SU: "Suspendido",
    NS: "Nuevas suscripciones disponibles",
    RA: "Reemisiones y altas disponibles",
  },
  groupName: {
    // Claims
    [GroupName.MedicalSabio]: "Medical Sabi@",
    [GroupName.Adjusters]: "Dictaminador",
    [GroupName.MedicalOps]: "Dictaminador - Director Médico",
    [GroupName.Chiefs]: "Dictaminador - Director CEO",
    // General
    [GroupName.AdminEdit]: "Admin Edit",
    [GroupName.AdminRead]: "Admin Read",
    [GroupName.SuperAdmin]: "Super Admin",
    [GroupName.MfaAdmin]: "MFA Admin",
    [GroupName.Medical]: "Medical",
    [GroupName.Developers]: "Desarrollador",
    [GroupName.Doctors]: "Doctor",
    [GroupName.DoctorLeads]: "Doctor director",
    [GroupName.Operations]: "Operaciones",
    [GroupName.Providers]: "Proveedor",
    [GroupName.OpsHeads]: "Directores de operaciones",
    [GroupName.ManualPayments]: "Grupo de pagos manuales",
    [GroupName.SalesManagers]: "Sales Manager",
    [GroupName.Accounting]: "Contador/a",
    [GroupName.Impersonators]: "Impersonators",
    [GroupName.SuperDoctor]: "Super Doctor",
    [GroupName.OpsFinance]: "Ops Finanzas",
    [GroupName.DoctorAssistant]: "Doctor Assistant",
    [GroupName.IncentiveCodeAdmins]: "Incentive Code Admins",
    [GroupName.InternalDoctors]: "Doctor cercano",
    [GroupName.ExternalDoctors]: "Doctor lejano",
    [GroupName.VcDoctors]: "Videoconsultas",
    [GroupName.IpcDoctors]: "Consultas Presenciales",
    [GroupName.NightCallDoctors]: "Llamadas nocturnas",
    [GroupName.ChatDoctors]: "Chat",
    [GroupName.MentalHealthDoctors]: "Doctor de Membresía Mental",
    [GroupName.MedicalAssigners]: "Asignadores médicos",
    [GroupName.SubscribeApplications]: "Aprobar aplicaciones",
    // finance
    [GroupName.PayoutTierTwo]: "Authorización de pagos tier 2",
    [GroupName.PayoutUnlimited]: "Authorización de pagos tier ilimitado",
    [GroupName.PaymentsSabio]: "Pagos Sabi@",
    [GroupName.PaymentsHead]: "Pagos Head",
    // Company experience
    [GroupName.CompanyExperienceUnit]: "Company Experience Unit",
  },
  scheduledTypes: {
    scheduled: "Programado",
    notScheduled: "No programado",
  },
  months: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dec"],
  applicationItemCheck: {
    [HealthPlanApplicationItemCheckState.Pn]: States.Pending,
    [HealthPlanApplicationItemCheckState.Ps]: States.Approved,
    [HealthPlanApplicationItemCheckState.Fl]: States.Rejected,
    [HealthPlanApplicationItemCheckState.Mi]: States.MoreInfoNeeded,
  },
  eventTask: {
    [TaskState.Pending]: "Pendiente",
    [TaskState.Passed]: "Aprobado",
    [TaskState.Failed]: "Fallido",
    [TaskState.InProgress]: "En progreso",
    [TaskState.NotApplicable]: "No aplica",
  },
  eventQuoteLetters: {
    [CaseEventQuoteLetterLetterType.DoctorsOfficeProcedureApprovalLetter]: "Doctor: Procedimiento en consultorio aprobado",
    [CaseEventQuoteLetterLetterType.ProvisionalDoctorsEventLetter]: "Doctor: Carta provisional",
    [CaseEventQuoteLetterLetterType.ProvisionalDoctorsOfficeProcedureLetter]: "Doctor: Procedimiento en consultorio provisional",
    [CaseEventQuoteLetterLetterType.ProvisionalHospitalEventLetter]: "Hospital: Carta provisional",
    [CaseEventQuoteLetterLetterType.ProvisionalMemberEventLetter]: "Soci@: Carta provisional",
    [CaseEventQuoteLetterLetterType.ProvisionalMemberUrgencyLetter]: "Soci@: Carta urgencia provisional",
    [CaseEventQuoteLetterLetterType.ProvisionalMembersOfficeProcedureLetter]: "Soci@: Procedimiento en consultorio provisional",
    [CaseEventQuoteLetterLetterType.ProvisionalUrgencyHospitalLetter]: "Hospital: Carta urgencia provisional",
  },
  checkState: {
    [CheckState.Pending]: States.Pending,
    [CheckState.Passed]: States.Approved,
    [CheckState.Failed]: States.Rejected,
    [CheckState.MoreInfoNeeded]: States.MoreInfoNeeded,
  },
  profilingDataCheckState: {
    [ProfilingDataCheckState.Pn]: States.Pending,
    [ProfilingDataCheckState.Ps]: States.Approved,
    [ProfilingDataCheckState.Fl]: States.Rejected,
    [ProfilingDataCheckState.Mi]: States.MoreInfoNeeded,
  },
  profilingMedicalCheckState: {
    [ProfilingDataCheckState.Pn]: States.NotAudited,
    [ProfilingDataCheckState.Ps]: States.Audited,
    [ProfilingDataCheckState.Fl]: States.Rejected,
    [ProfilingDataCheckState.Mi]: States.MoreInfoNeeded,
  },
  applicationItemCheckType: {
    [HealthPlanApplicationItemCheckCheckType.Dp]: "Prueba de dependencia",
    [HealthPlanApplicationItemCheckCheckType.Id]: "ID",
    [HealthPlanApplicationItemCheckCheckType.Rf]: "RFC",
    [HealthPlanApplicationItemCheckCheckType.Cr]: "CURP",
    [HealthPlanApplicationItemCheckCheckType.Sl]: "Selfie",
    [HealthPlanApplicationItemCheckCheckType.Ad]: "Verificación de domicilio",
    [HealthPlanApplicationItemCheckCheckType.Np]: "No políticamente expuesto",
    [HealthPlanApplicationItemCheckCheckType.Nb]: "Legal",
    [HealthPlanApplicationItemCheckCheckType.Nr]: "No restringido",
    [HealthPlanApplicationItemCheckCheckType.Sw]: "Selfie aprobará",
    [HealthPlanApplicationItemCheckCheckType.Md]: "Médico",
    [HealthPlanApplicationItemCheckCheckType.Ot]: "Otro",
    [HealthPlanApplicationItemCheckCheckType.Oc]: "Ocupación",
    [HealthPlanApplicationItemCheckCheckType.Rl]: "Nivel de riesgo",
    [HealthPlanApplicationItemCheckCheckType.Ti]: "Información fiscal",
  },
  healthPlanApplicationState: {
    [HealthPlanApplicationState.Draft]: States.Draft,
    [HealthPlanApplicationState.ReviewNeeded]: States.ReviewNeeded,
    [HealthPlanApplicationState.MoreInfoNeeded]: States.MoreInfoNeeded,
    [HealthPlanApplicationState.PaymentFailed]: States.PaymentFailed,
    [HealthPlanApplicationState.ApprovedForPayment]: States.ApprovedForPayment,
    [HealthPlanApplicationState.Rejected]: States.Rejected,
    [HealthPlanApplicationState.Subscribed]: States.Subscribed,
    [HealthPlanApplicationState.Cancelled]: States.Cancelled,
    [HealthPlanApplicationState.Closed]: States.Closed,
    [HealthPlanApplicationState.Error]: States.Error,
    [HealthPlanApplicationState.WaitingSubscriber]: States.WaitingSubscriber,
    [HealthPlanApplicationState.WaitingConditions]: States.WaitingConditions,
    [HealthPlanApplicationState.ToBeRenewed]: States.ToBeRenewed,
  },
  opsOrMedicalAtenttionFilterOptions: {
    [OpsOrMedicalAtenttionFilterOptions.MoreInfoNeededMedicalFilter]: "Falta información - Medical",
    [OpsOrMedicalAtenttionFilterOptions.MoreInfoNeededOpsFilter]: "Falta información - Sabios",
    [OpsOrMedicalAtenttionFilterOptions.ReviewNeededMedicalFilter]: "Pendiente de revisión - Medical",
    [OpsOrMedicalAtenttionFilterOptions.ReviewNeededOpsFilter]: "Pendiente de revisión - Sabios",
  },
  healthPlanApplicationSource: {
    [HealthPlanApplicationSource.App]: "App",
    [HealthPlanApplicationSource.Web]: "Web",
    [HealthPlanApplicationSource.Hybrid]: "Híbrida",
    [HealthPlanApplicationSource.Renewal]: "Renovación",
    [HealthPlanApplicationSource.Reissue]: "Reemisión",
    [HealthPlanApplicationSource.Collective]: "Colectivo",
    [HealthPlanApplicationSource.CollectiveAdditional]: "Colectivo Adicional",
    [HealthPlanApplicationSource.CollectiveMigration]: "Migración Colectivo",
    [HealthPlanApplicationSource.CollectiveReissue]: "Remisión Colectivo",
    [HealthPlanApplicationSource.CollectiveRenewal]: "Renovación Colectivo",
  },
  applicationItemState: {
    [HealthPlanApplicationItemState.Draft]: States.Draft,
    [HealthPlanApplicationItemState.ReviewNeeded]: States.ReviewNeeded,
    [HealthPlanApplicationItemState.MoreInfoNeeded]: States.MoreInfoNeeded,
    [HealthPlanApplicationItemState.Rejected]: States.Rejected,
    [HealthPlanApplicationItemState.Approved]: States.Approved,
    [HealthPlanApplicationItemState.Cancelled]: States.Cancelled,
    [HealthPlanApplicationItemState.WaitingConditions]: States.WaitingConditions,
    [HealthPlanApplicationItemState.ToBeRenewed]: States.ToBeRenewed,
  },
  applicationItemRejectionReason: {
    [HealthPlanApplicationItemRejectionReason.Documents]: "Documentos inválidos",
    [HealthPlanApplicationItemRejectionReason.Medical]: "Médica",
    [HealthPlanApplicationItemRejectionReason.Kyc]: "KYC",
    [HealthPlanApplicationItemRejectionReason.OutOfArea]: "Fuera de zona de cobertura",
    [HealthPlanApplicationItemRejectionReason.Occupation]: "Rechazo de ocupación",
    [HealthPlanApplicationItemRejectionReason.Other]: "Otra razón",
    [HealthPlanApplicationItemRejectionReason.Covid]: "COVID",
  },
  insuredAmountCents: {
    [InsuredAmountCents.M_1_5]: "$1,500,000",
    [InsuredAmountCents.M_5_0]: "$5,000,000",
    [InsuredAmountCents.M_15_0]: "$15,000,000",
  },
  coinsuranceConfig: {
    [CoInsuranceConfigFactor.Ci_30_50]: "30% con $50,000 de límite",
    [CoInsuranceConfigFactor.Ci_10_30]: "10% con $30,000 de límite",
    [CoInsuranceConfigFactor.Ci_10_10]: "10% con $10,000 de límite",
  },
  altInsuredAmountCents: {
    [InsuredAmountCents.M_1_5]: "$1.5 M",
    [InsuredAmountCents.M_5_0]: "$5 M",
    [InsuredAmountCents.M_15_0]: "$15 M",
  },
  relationship: {
    [HealthPlanApplicationItemRelationship.Wf]: "Esposa",
    [HealthPlanApplicationItemRelationship.Hs]: "Esposo",
    [HealthPlanApplicationItemRelationship.Dg]: "Hija",
    [HealthPlanApplicationItemRelationship.Sn]: "Hijo",
    [HealthPlanApplicationItemRelationship.Sl]: "Contratante",
    [HealthPlanApplicationItemRelationship.Ft]: "Padre",
    [HealthPlanApplicationItemRelationship.Mt]: "Madre",
    [HealthPlanApplicationItemRelationship.Gp]: "Abuel@",
    [HealthPlanApplicationItemRelationship.Sb]: "Herman@",
    [HealthPlanApplicationItemRelationship.Gc]: "Niet@",
    [HealthPlanApplicationItemRelationship.Np]: "Sobrin@",
    [HealthPlanApplicationItemRelationship.Ot]: "Otro",
  },
  CollectiveRelationshipType: {
    [CollectiveRelationshipType.Mother]: "Madre",
    [CollectiveRelationshipType.Father]: "Padre",
    [CollectiveRelationshipType.Wife]: "Esposa",
    [CollectiveRelationshipType.Husband]: "Esposo",
    [CollectiveRelationshipType.Daughter]: "Hija",
    [CollectiveRelationshipType.Son]: "Hijo",
  },
  healthPlanState: {
    [HealthPlanState.Ac]: "Plan activo",
    [HealthPlanState.In]: "Plan inactivo",
  },
  HealthPlanSubscriptionType: {
    [HealthPlanSubscriptionType.Mn]: "Mensual",
    [HealthPlanSubscriptionType.Qr]: "Quincenal",
    [HealthPlanSubscriptionType.Yr]: "Anual",
    [HealthPlanSubscriptionType.Bn]: "Bianual",
  },
  HealthPlanSponsorType: {
    [SponsorType.Myself]: "Individual",
    [SponsorType.Others]: "Otros Familiares",
    [SponsorType.MyselfAndOthers]: "Familiar",
  },
  medicalReccomendation: {
    [MedicalRecommendation.AutomaticSubscription]: "Suscripción automática",
    [MedicalRecommendation.NoExclusions]: "Sin exclusiones",
    [MedicalRecommendation.Rejection]: "Rechazada",
    [MedicalRecommendation.EvaluationRequired]: "Evaluación requerida",
    [MedicalRecommendation.DefinitiveExclusions]: "Exclusiones definitivas",
  },
  claimRulingChecksDeclinedReason: {
    [ClaimRulingChecksDeclinedReason.Hp]: "Vigencia de póliza",
    [ClaimRulingChecksDeclinedReason.Mi]: "Identidad del socio",
    [ClaimRulingChecksDeclinedReason.On]: "Dentro de red",
    [ClaimRulingChecksDeclinedReason.Wt]: "Periodos de espera",
    [ClaimRulingChecksDeclinedReason.Ex]: "Exclusiones",
    [ClaimRulingChecksDeclinedReason.Pr]: "Preexistencias",
    [ClaimRulingChecksDeclinedReason.Mn]: "Necesidad médica",
    [ClaimRulingChecksDeclinedReason.Sc]: "Suma asegurada",
    [ClaimRulingChecksDeclinedReason.Pi]: "Factura y receta coindiden",
    [ClaimRulingChecksDeclinedReason.Rl]: "Servicio relacionado límite de pago automático alcanzado",
    [ClaimRulingChecksDeclinedReason.Er]: "Vale electrónico ya reembolsado",
    [ClaimRulingChecksDeclinedReason.Mr]: "Límite pago mensual alcanzado",
  },
  caseEventCategory: {
    [CaseEventCategory.Urgency]: "Urgencia",
    [CaseEventCategory.Admission]: "Admisión",
    [CaseEventCategory.AmbulatorySurgery]: "Cirugía ambulatoria (Obsoleta)",
    [CaseEventCategory.SurgeryAdmission]: "Cirugía admisión (Obsoleta)",
    [CaseEventCategory.UnscheduledSurgery]: "Cirugía no programada (Obsoleta)",
    [CaseEventCategory.OfficeProcedure]: "Procedimiento en consultorio",
    [CaseEventCategory.Surgery]: "Cirugía",
    [CaseEventCategory.OutpatientProcedure]: "Procedimiento en sala",
  },

  caseEventFinalAmountsState: {
    [CaseEventFinalAmountsState.Pending]: "Pendiente",
    [CaseEventFinalAmountsState.InReview]: "En Revisión",
    [CaseEventFinalAmountsState.Approved]: "Aprobado",
  },

  caseEventClassOptions: {
    [CaseEventClassOptions.Outpatient]: "Ambulatorio",
    [CaseEventClassOptions.Inpatient]: "Internamiento",
  },

  caseEventAdmissionSource: {
    [CaseEventAdmissionSource.Referral]: "Referencia",
    [CaseEventAdmissionSource.Transfer]: "Traslado de otro hospital",
    [CaseEventAdmissionSource.Emergency]: "Urgencias",
  },
  caseEventDischargeReason: {
    [CaseEventDischargeReason.Improvement]: "Mejoría",
    [CaseEventDischargeReason.PalliativeCare]: "Máximo beneficio",
    [CaseEventDischargeReason.VoluntaryDischarge]: "Alta voluntaria",
    [CaseEventDischargeReason.Transfer]: "Traslado a otro hospital",
    [CaseEventDischargeReason.WoundHealing]: "Curación",
  },
  caseEventMemberStage: {
    [CaseEventMemberStage.Informed]: "Informado",
    [CaseEventMemberStage.OnTheWay]: "En camino al hospital",
    [CaseEventMemberStage.Hospitalized]: "Hospitalizado",
    [CaseEventMemberStage.Discharged]: "Alta",
  },
  rulingCheckState: {
    [RulingCheckState.Failed]: States.Rejected,
    [RulingCheckState.Passed]: States.Approved,
    [RulingCheckState.Pending]: States.Pending,
    [RulingCheckState.Cancelled]: States.Cancelled,
  },
  serviceCoverageRulingState: {
    [ServiceCoverageRulingState.Pa]: States.PartiallyApproved,
    [ServiceCoverageRulingState.Ac]: States.Approved,
    [ServiceCoverageRulingState.Dc]: States.Rejected,
    [ServiceCoverageRulingState.Ir]: States.InReview,
    [ServiceCoverageRulingState.Pn]: States.Pending,
    [ServiceCoverageRulingState.Cn]: States.Cancelled,
  },
  emrType: {
    [EmrType.DuplicatedConsult]: "Consulta duplicada",
    [EmrType.InPersonConsult]: "Consulta presencial",
    [EmrType.OnDemandConsult]: "VC Normal",
    [EmrType.PreventiveVc]: "VC Preventiva",
    [EmrType.UnclassifiedConsult]: "Consulta sin clasificar",
    [EmrType.RehabilitationTherapy]: "Terapia de Rehabilitación",
    [EmrType.FolowupNotes]: "Notas de seguimiento",
    [EmrType.IgnoreRecord]: "Ignorar registro",
  },
  noteType: {
    [NoteType.Hospitalization]: "Hospitalización",
    [NoteType.InPerson]: "Presencial",
    [NoteType.Video]: "Videoconsulta",
    [NoteType.Included]: "Gratis",
    [NoteType.Urgency]: "Urgencia",
    [NoteType.RehabilitationTherapy]: "Terapia de rehabilitación",
    [NoteType.Chat]: "Chat",
    [NoteType.MentalMembership]: "Membresía SaludMental",
    CANCEL: "Cancelar",
  },
  serviceCoveragePayoutPayment: {
    [ServiceCoveragePayoutMode.Direct]: "Pago directo",
    [ServiceCoveragePayoutMode.Reimbursement]: "Reembolso",
    [ServiceCoveragePayoutMode.ReimbursementCfdiRecipientMember]: "Reembolso con factura a nombre del soci@",
    [ServiceCoveragePayoutMode.DirectConsultingRoom]: "Pago directo en consultorio",
    [ServiceCoveragePayoutMode.DirectHospital]: "Pago directo en hospital",
    [ServiceCoveragePayoutMode.Compensation]: "Indemnización",
  },
  serviceCoveragePayoutState: {
    [ServiceCoveragePayoutState.Requested]: States.Requested,
    [ServiceCoveragePayoutState.Draft]: States.Draft,
    [ServiceCoveragePayoutState.InSitu]: States.InSitu,
  },
  servicePaymentMethodsPayInMethods: {
    [PayInMethods.Card]: "Tarjeta de crédito o débito",
    [PayInMethods.InSitu]: "Pago in situ",
  },
  servicePaymentMethodsPaymentMethods: {
    [PaymentMethodsPaymentMethodType.Cr]: "Tarjeta",
    [PaymentMethodsPaymentMethodType.Se]: "Cuenta CLABE",
    [PaymentMethodsPaymentMethodType.Ox]: "Pago en Oxxo",
  },
  serviceRulingDifficulty: {
    [ServiceRulingDifficulty.Green]: "🟢  Pudo ser automática",
    [ServiceRulingDifficulty.Red]: "🔴  Evaluación humana necesaria",
    [ServiceRulingDifficulty.Yellow]: "🟡  Intermedia",
  },
  paymentMethodsPaymentMethods: {
    [PaymentMethodsPaymentMethodType.Cr]: "Tarjeta",
    [PaymentMethodsPaymentMethodType.Ox]: "Pago en Oxxo",
    [PaymentMethodsPaymentMethodType.Pa]: "PayPal",
    [PaymentMethodsPaymentMethodType.Mn]: "Manual",
    [PaymentMethodsPaymentMethodType.Se]: "Cuenta CLABE",
  },
  InvoiceContentTypes: {
    [InvoiceContentTypes.On]: "Video consulta",
    [InvoiceContentTypes.Ip]: "Consulta presencial",
    [InvoiceContentTypes.Hp]: "Membresía",
    [InvoiceContentTypes.Se]: "Reclamación",
    [InvoiceContentTypes.Ha]: "Endoso",
    [InvoiceContentTypes.Ot]: "Horas registradas de video consulta",
    [InvoiceContentTypes.Po]: "Orden de Farmacia",
  },
  IncomePaymentFlow: {
    [IncomePaymentFlow.Out]: "Pagos",
    [IncomePaymentFlow.In]: "Cobros",
  },
  CustomerPaymentFlowType: {
    [CustomerPaymentFlowType.Incoming]: "Pagos",
    [CustomerPaymentFlowType.Outgoing]: "Cobros",
  },
  ElectronicVoucherElectronicVoucherOperationState: {
    [ElectronicVoucherElectronicVoucherOperationState.Is]: "Emitida",
    [ElectronicVoucherElectronicVoucherOperationState.Ca]: "Cancelada",
    [ElectronicVoucherElectronicVoucherOperationState.Ip]: "Cancelación en progreso",
    [ElectronicVoucherElectronicVoucherOperationState.Cf]: "Cancelación fallida",
    [ElectronicVoucherElectronicVoucherOperationState.Ni]: "No emitida",
    [ElectronicVoucherElectronicVoucherOperationState.Dr]: "Borrador",
    [ElectronicVoucherElectronicVoucherOperationState.Nc]: "Non Collectible Voucher",
    [ElectronicVoucherElectronicVoucherOperationState.Cb]: "Cancelado sin emisión",
    [ElectronicVoucherElectronicVoucherOperationState.Rp]: "Reemplazada",
    [ElectronicVoucherElectronicVoucherOperationState.Nd]: "Non deductible",
  },
  ProcedureType: {
    [ProcedureName.Ultrasound]: "Ultrasonido",
    [ProcedureName.Electrocardiogram]: "Electrocardiograma",
    [ProcedureName.Papanicolaou]: "Papanicolaou",
    [ProcedureName.BandagesSplintPlacementPlaster]: "Colocación de férula",
    [ProcedureName.Sutures]: "Sutura",
    [ProcedureName.AbscessDrainage]: "Drenado de abscesos",
    [ProcedureName.NasalBleedingControl]: "Control de sangrado nasal",
    [ProcedureName.AudiometrySpeechAudiometryImpedanciometryVideonystagmography]: "Audiometría/Logoaudiometría/Impedanciometria/Videonistagmografía",
    [ProcedureName.Other]: "Otro",
  },
  TransactionTypes: {
    [TransactionTransactionType.At]: "Autorización",
    [TransactionTransactionType.Cr]: "Crédito",
    [TransactionTransactionType.Db]: "Débito",
    [TransactionTransactionType.Py]: "Pago (Outbound)",
    [TransactionTransactionType.Rf]: "Reembolso",
    [TransactionTransactionType.Cn]: "Cancelación",
    [TransactionTransactionType.Ps]: "Pago en proceso",
    [TransactionTransactionType.Fw]: "Fondos retirados",
    [TransactionTransactionType.Si]: "Stripe setup intent",
    [TransactionTransactionType.Cp]: "Stripe payment intent",
    [TransactionTransactionType.Sr]: "Sourcing",
    [TransactionTransactionType.Bi]: "Balance Increase",
    [TransactionTransactionType.Bd]: "Balance Decrease",
    [TransactionTransactionType.Po]: "Paypal order creation",
    [TransactionTransactionType.Pc]: "Paypal order capture",
    [TransactionTransactionType.Pr]: "Reembolso de pago",
    [TransactionTransactionType.Uc]: "Incobrable",
  },
  InvoiceStates: {
    [InvoiceState.Paid]: "Pagado",
    [InvoiceState.Refunded]: "Reembolsado",
    [InvoiceState.PaymentRejected]: "Pago rechazado",
    [InvoiceState.Cancelled]: "Cancelado",
    [InvoiceState.NotPaid]: "No pagado",
    [InvoiceState.ProcessingPayment]: "Procesando pago",
    [InvoiceState.PaymentOrderSent]: "Pago enviado a stp",
    [InvoiceState.Applied]: "Aplicado",
    [InvoiceState.FundsWithdrawn]: "Fondos retirados",
    [InvoiceState.Draft]: "Borrador",
    [InvoiceState.Uncollectible]: "Uncollectible",
    [InvoiceState.InReview]: "En revisión",
    [InvoiceState.Unpayable]: "Impagable",
  },
  TransactionPaymentGatewayResultTypes: {
    [TransactionPaymentGatewayResult.Fl]: "Fallido",
    [TransactionPaymentGatewayResult.Ok]: "Exitoso",
  },
  HealthPlanAmendmentAmendmentCategory: {
    [HealthPlanAmendmentAmendmentCategory.Em]: "Sin especificar",
    [HealthPlanAmendmentAmendmentCategory.Cn]: "Cancelación voluntaria",
    [HealthPlanAmendmentAmendmentCategory.Db]: "Cancelación por deuda",
    [HealthPlanAmendmentAmendmentCategory.Md]: "Datos",
    [HealthPlanAmendmentAmendmentCategory.Pp]: "Parámetros del producto",
    [HealthPlanAmendmentAmendmentCategory.Fr]: "Fraude",
    [HealthPlanAmendmentAmendmentCategory.Bf]: "Usuario beta a Coronaid",
    [HealthPlanAmendmentAmendmentCategory.Sa]: "Subscripción",
    [HealthPlanAmendmentAmendmentCategory.Bi]: "Información de factura",
    [HealthPlanAmendmentAmendmentCategory.Mt]: "Maternidad",
    [HealthPlanAmendmentAmendmentCategory.Ac]: "Cancelación de complementos",
    [HealthPlanAmendmentAmendmentCategory.Dt]: "Dental",
    [HealthPlanAmendmentAmendmentCategory.Cs]: "Collective subscription",
    [HealthPlanAmendmentAmendmentCategory.As]: "Collective additional subscription",
    [HealthPlanAmendmentAmendmentCategory.Cd]: "Collective dental amendment",
    [HealthPlanAmendmentAmendmentCategory.Cm]: "Collective maternity amendment",
    [HealthPlanAmendmentAmendmentCategory.Ccc]: "Collective certificates cancellation",
    [HealthPlanAmendmentAmendmentCategory.Cgc]: "Collective group cancellation",
    [HealthPlanAmendmentAmendmentCategory.Cac]: "Collective addon cancellation",
    [HealthPlanAmendmentAmendmentCategory.Cpc]: "Collective policy cancellation",
    [HealthPlanAmendmentAmendmentCategory.Cdc]: "Collective debt cancellation",
    [HealthPlanAmendmentAmendmentCategory.Cic]: "Collective intl coverage",
    [HealthPlanAmendmentAmendmentCategory.Ic]: "Intl coverage",
    [HealthPlanAmendmentAmendmentCategory.Mdt]: "Membresía dental",
    [HealthPlanAmendmentAmendmentCategory.Cmd]: "Collective Dental Membership Amendment",
    [HealthPlanAmendmentAmendmentCategory.Im]: "Intl Coverage Mawdy Amendment",
    [HealthPlanAmendmentAmendmentCategory.Cim]: "Collective Intl Mawdy Coverage Amendment",
    [HealthPlanAmendmentAmendmentCategory.Mmt]: "Mental Membership Amendment",
    [HealthPlanAmendmentAmendmentCategory.Cmm]: "Collective Mental Membership Amendment",
  },
  pharmacyOrderState: {
    [PharmacyOrderState.Cm]: "Completada",
    [PharmacyOrderState.Dr]: "Borrador",
    [PharmacyOrderState.Ip]: "En progreso",
    [PharmacyOrderState.Cl]: "Cancelada",
  },
  complianceAlertCategory: {
    [ComplianceAlertCategory.Unknown]: "Desconocida",
    [ComplianceAlertCategory.PepCheckFailed]: "Persona políticamente expuesta",
    [ComplianceAlertCategory.RestrictedCheckFailed]: "Persona restringida",
    [ComplianceAlertCategory.BannedCheckFailed]: "Persona bloqueada",
    [ComplianceAlertCategory.IdCheckFailed]: "Fallo en check de Id",
    [ComplianceAlertCategory.DuplicatedAddress]: "Dirección duplicada",
    [ComplianceAlertCategory.AddressUpdated]: "Dirección actualizada",
    [ComplianceAlertCategory.Cancellation]: "Cancelación",
    [ComplianceAlertCategory.HighRiskLevel]: "Nivel de riesgo alto",
    [ComplianceAlertCategory.OccupationCheckFailed]: "Ocupación de riesgo",
    [ComplianceAlertCategory.BirthCountryCheckFailed]: "Fallo en check de país de nacimiento",
    [ComplianceAlertCategory.ComplianceSurpassedRefundLimit]: "Se sobrepasó el límite para reembolsos",
    [ComplianceAlertCategory.ComplianceSurpassedMonthlyLimit]: "Se sobrepasó el límite mensual de reembolsos",
    [ComplianceAlertCategory.Location]: "Fallo en check de ubicación",
    [ComplianceAlertCategory.InternalOperations]: "Operaciones internas",
    [ComplianceAlertCategory.GeneralUnusualOperations]: "Operaciones generales inusuales",
    [ComplianceAlertCategory.UnusualCustomerBehaviorContractingProducts]: "Comportamiento inusual del socio en la contratación del plan",
    [ComplianceAlertCategory.UnusualCustomerBehaviorPerformingOperations]: "Comportamiento inusual del socio en el uso de servicios",
    [ComplianceAlertCategory.UnusualCustomerBehaviorPaymentAmount]: "Comportamiento inusual del socio en los montos de pago",
    [ComplianceAlertCategory.UnusualNumberOfAmendmentsInAYear]: "Cantidad inusual de endosos anuales",
    [ComplianceAlertCategory.HighAmountTransaction]: "Alerta por transacción que supera el monto establecido por PLD",
  },
  serviceTypeSpecification: {
    [ServiceTypeSpecification.EmergencyAmbulance]: "Emergencia",
    [ServiceTypeSpecification.TransferAmbulance]: "Traslado",
    [ServiceTypeSpecification.HospitalEmergency]: "Urgencias",
    [ServiceTypeSpecification.HospitalAdmission]: "Admisión",
    [ServiceTypeSpecification.TreatingPhysician]: "Médico tratante",
    [ServiceTypeSpecification.Surgeon]: "Cirujano",
    [ServiceTypeSpecification.FirstHelper]: "Primer ayudante",
    [ServiceTypeSpecification.SecondHelper]: "Segundo ayudante",
    [ServiceTypeSpecification.Anesthesiologist]: "Anestesiólogo",
    [ServiceTypeSpecification.ConsultingPhysician]: "Interconsultante",
  },

  coverageTags: {
    [CoverageTag.CheckUp]: "Check Up",
    [CoverageTag.MajorMedicalExpense]: "Gastos médicos mayores",
    [CoverageTag.InPersonConsult]: "Consulta presencial",
    [CoverageTag.Medicine]: "Medicamento",
    [CoverageTag.Vaccine]: "Vacuna",
    [CoverageTag.VideoConsultation]: "Videoconsulta",
    [CoverageTag.OfficeProcedure]: "Procedimiento en consultorio",
    [CoverageTag.Maternity]: "Maternidad",
    [CoverageTag.ClinicalLaboratoryExamsDiagnostic]: "Exámenes de laboratorio clínico diagnóstico",
    [CoverageTag.ClinicalLaboratoryExamsPrevention]: "Exámenes de laboratorio clínico prevención",
    [CoverageTag.ImagingAndCabinetExamsDiagnostic]: "Exámenes de gabinete e imagen diagnóstico",
    [CoverageTag.ImagingAndCabinetExamsPrevention]: "Exámenes de gabinete e imagen prevención",
    [CoverageTag.ChatConsult]: "Consulta por chat",
  },

  complianceReportType: {
    [ComplianceReportType.Relevant]: "Relevante",
    [ComplianceReportType.Unusual]: "Inusual",
    [ComplianceReportType.Concerning]: "Preocupante",
    [ComplianceReportType.PldFile]: "Expedientes",
    [ComplianceReportType.Alerts]: "Alertas",
    [ComplianceReportType.File]: "Datos Completos de Personas",
  },
  serviceProcessState: {
    [ServiceProcessState.ProcessStarted]: "Proceso iniciado",
    [ServiceProcessState.ReviewingEvidences]: "Revisando Evidencias",
    [ServiceProcessState.Ruling]: "Dictaminando",
    [ServiceProcessState.RegisteringAmounts]: "Registrando Montos",
    [ServiceProcessState.ProcessingPayments]: "Procesando pagos",
    [ServiceProcessState.ProcessFinalized]: "Proceso finalizado",
    [ServiceProcessState.Cancelled]: "Proceso cancelado",
  },
  electronicVoucherType: {
    [SatVoucherType.Income]: "Ingreso",
    [SatVoucherType.Expenditure]: "Egreso",
    [SatVoucherType.Payment]: "Pago",
  },
  electronicVoucherVoucherType: {
    [ElectronicVoucherVoucherType.I]: "Ingreso",
    [ElectronicVoucherVoucherType.E]: "Egreso",
    [ElectronicVoucherVoucherType.P]: "Pago",
  },

  electronicVoucherOperationState: {
    [ElectronicVoucherElectronicVoucherOperationState.Is]: "Emitido",
    [ElectronicVoucherElectronicVoucherOperationState.Ca]: "Cancelado",
    [ElectronicVoucherElectronicVoucherOperationState.Ip]: "Cancelación en Proceso",
    [ElectronicVoucherElectronicVoucherOperationState.Cf]: "Cancelación Fallida",
    [ElectronicVoucherElectronicVoucherOperationState.Ni]: "No Emitido",
    [ElectronicVoucherElectronicVoucherOperationState.Dr]: "Borrador",
    [ElectronicVoucherElectronicVoucherOperationState.Nc]: "Monto no Facturable",
    [ElectronicVoucherElectronicVoucherOperationState.Cb]: "Cancelado antes de Emisión",
    [ElectronicVoucherElectronicVoucherOperationState.Rp]: "Reemplazado",
    [ElectronicVoucherElectronicVoucherOperationState.Nd]: "No deducible",
  },
  satOperationState: {
    [SatOperationTypeStatus.Issued]: "Emitido",
    [SatOperationTypeStatus.Cancelled]: "Cancelado",
    [SatOperationTypeStatus.CancellationInProgress]: "Cancelación en Proceso",
    [SatOperationTypeStatus.CancellationFailed]: "Cancelación Fallida",
    [SatOperationTypeStatus.NotIssued]: "No Emitido",
    [SatOperationTypeStatus.Draft]: "Borrador",
    [SatOperationTypeStatus.NonCollectibleVoucher]: "Monto no Facturable",
    [SatOperationTypeStatus.CancelledBeforeIssued]: "Cancelado antes de Emisión",
    [SatOperationTypeStatus.Replaced]: "Reemplazado",
    [SatOperationTypeStatus.NonDeductible]: "No deducible",
  },
  SatPaymentForm: {
    [SatPaymentForm.Cash]: "Efectivo",
    [SatPaymentForm.Check]: "Cheque",
    [SatPaymentForm.Compensation]: "Compensación",
    [SatPaymentForm.Condonation]: "Condonación",
    [SatPaymentForm.CreditCard]: "Tarjeta de crédito",
    [SatPaymentForm.DebitCard]: "Tarjeta de débito",
    [SatPaymentForm.DigitalCurrency]: "Moneda digital",
    [SatPaymentForm.PaymentGateway]: "Payment Gateway",
    [SatPaymentForm.ToBeDefined]: "Por definir",
    [SatPaymentForm.WireTransfer]: "Transferencia bancaria",
  },
  SatPaymentMethod: {
    [SatPaymentMethod.PaymentInInstallmentsOrDeferred]: "Pago a cuotas o diferido",
    [SatPaymentMethod.PaymentInOneExhibition]: "Pago en una sola exhibición",
  },
  SatCancellationServiceCancellationState: {
    [SatCancellationServiceCancellationState.CancelledWithAcceptance]: "Cancelado con aceptación",
    [SatCancellationServiceCancellationState.CancelledWithoutAcceptance]: "Cancelado sin aceptación",
    [SatCancellationServiceCancellationState.DeadlineOverdue]: "Fecha límite vencida",
    [SatCancellationServiceCancellationState.InProgress]: "En progreso",
    [SatCancellationServiceCancellationState.RequestDeclined]: "Solicitud rechazada",
  },

  complianceAlertAnotation: {
    [ComplianceAlertAnnotation.NotReviewed]: "Sin revisar",
    [ComplianceAlertAnnotation.ReviewedByAnybody]: "Revisado por cualquiera",
    [ComplianceAlertAnnotation.ReviewedOnlyByCommittee]: "Revisado sólo por comité",
    [ComplianceAlertAnnotation.ReviewedOnlyByOfficer]: "Revisado sólo por oficial",
  },
  covidVaccinationStatus: {
    [CovidVaccinationStatus.PartiallyVaccinated]: "Vacunación parcial",
    [CovidVaccinationStatus.FullyVaccinated]: "Vacunación completa",
    [CovidVaccinationStatus.VaccinatedWithProof]: "Vacunación con certificado",
    [CovidVaccinationStatus.NotVaccinated]: "Sin vacunación",
    [CovidVaccinationStatus.NoInformationAvailable]: "Sin información",
    [CovidVaccinationStatus.WaitingToShareProof]: "Esperando certificado",
  },
  businessStakeHolderState: {
    [StakeHolderState.Active]: "Activo",
    [StakeHolderState.Inactive]: "Inactivo",
  },
  productFamilies: {
    [ProductFamilies.Mini]: "SofiaPop",
    [ProductFamilies.Morado]: "Morado",
    [ProductFamilies.Colectivo]: "Colectivo",
  },
  previousProductType: {
    [PreviousProductType.Bundle]: "Bundle",
    [PreviousProductType.Collective]: "Colectivo",
    [PreviousProductType.B2C]: "B2C",
  },
  SMBCheckType: {
    [BusinessCheckCheckType.Ad]: "Verificación de domicilio",
    [BusinessStakeholderCheckCheckType.Np]: "No políticamente expuesto",
    [BusinessCheckCheckType.Nb]: "Legal",
    [BusinessCheckCheckType.Nr]: "No restringido",
    [BusinessCheckCheckType.Rl]: "Nivel de riesgo",
    [BusinessCheckCheckType.Tr]: "Constancia de Situación Fiscal",
    [BusinessCheckCheckType.Ts]: "Comprobante Digital de Firma Electrónica",
    [BusinessCheckCheckType.Td]: "Escritura Constitutiva o Contrato Social",
    [BusinessCheckCheckType.Co]: "Inscripción al Registro Público de Comercio",
    [BusinessCheckCheckType.Cs]: "Estado del contrato",
  },
  subscriptionTypeLong: {
    [SubscriptionType.Monthly]: "Mensual",
    [SubscriptionType.Yearly]: "Anual",
    [SubscriptionType.Biannually]: "Semestral",
    [SubscriptionType.Quarterly]: "Trimestral",
  },
  scheduledConsultState: {
    [ScheduledConsultState.Requested]: "Solicitada",
    [ScheduledConsultState.Scheduled]: "Agendada",
    [ScheduledConsultState.Confirmed]: "Confirmada",
    [ScheduledConsultState.DueSoon]: "Por iniciar",
    [ScheduledConsultState.Cancelled]: "Cancelada",
    [ScheduledConsultState.ToBeRequested]: "Por confirmar",
    [ScheduledConsultState.Done]: "Terminada",
    [ScheduledConsultState.SignedPrescription]: "Receta firmada",
  },
  scheduledConsultClassification: {
    [GrapheneScheduledConsultClassification.OnDemandConsult]: "Videoconsulta",
    [GrapheneScheduledConsultClassification.InPersonConsult]: "Presencial",
    [GrapheneScheduledConsultClassification.None]: "Sin clasificación",
  },
  /** @todo: Ask for proper translations */
  scheduledConsultClassificationDeprecated: {
    [GrapheneScheduledConsultClassificationDeprecated.OnDemandConsultPrimary]: "On demand consult primary",
    [GrapheneScheduledConsultClassificationDeprecated.OnDemandConsultWelcome]: "On demand consult welcome",
    [GrapheneScheduledConsultClassificationDeprecated.OnDemandConsultMain]: "On demandconsult main",
    [GrapheneScheduledConsultClassificationDeprecated.PediatricsPrimary]: "Pediatrics primary",
    [GrapheneScheduledConsultClassificationDeprecated.PediatricsWelcome]: "Pediatrics welcome",
    [GrapheneScheduledConsultClassificationDeprecated.RehabilitationTherapy]: "Rehabilitation therapy",
  },
  invoiceCounterpartyType: {
    [InvoiceCounterpartyType.Members]: "Soci@s",
    [InvoiceCounterpartyType.Doctors]: "Doctores",
    [InvoiceCounterpartyType.Businesses]: "Empresas",
    [InvoiceCounterpartyType.Providers]: "Proveedores",
  },

  invoicesQueryServiceType: {
    [InvoicesQueryServiceType.AmendmentSubscription]: "Subscripción",
    [InvoicesQueryServiceType.AmendmentCancelation]: "Cancelación",
    [InvoicesQueryServiceType.AmendmentCorrection]: "Corrección de datos",
    [InvoicesQueryServiceType.ClaimsAmbulance]: "Ambulancia",
    [InvoicesQueryServiceType.ClaimsHospitalService]: "Servicios Hospitalarios",
    [InvoicesQueryServiceType.ClaimsInPersonConsult]: "Consulta en persona",
    [InvoicesQueryServiceType.ClaimsLabStudies]: "Estudios",
    [InvoicesQueryServiceType.ClaimsMedicalEquipment]: "Equipo Médico a Domicilio",
    [InvoicesQueryServiceType.ClaimsMedicalFees]: "Honorarios Médicos",
    [InvoicesQueryServiceType.ClaimsMedicines]: "Medicamentos",
    [InvoicesQueryServiceType.ClaimsOnDemandConsult]: "Consulta virtual",
    [InvoicesQueryServiceType.ClaimsOtherServices]: "Otro servicios",
    [InvoicesQueryServiceType.ClaimsProstheticAndMechanicalEquipment]: "Prótesis y Equipo Mecánico",
    [InvoicesQueryServiceType.ClaimsRehabilitationTherapy]: "Terapia de Rehabilitación",
    [InvoicesQueryServiceType.PharmarcyOrder]: "Ordén de Farmacia",
    [InvoicesQueryServiceType.Incentive]: "Compensación Sofía Salud",
  },
  serviceType: {
    [ServiceType.MedicalFees]: "Honorarios Médicos",
    [ServiceType.HospitalService]: "Servicios Hospitalarios",
    [ServiceType.OtherService]: "Otros Servicios",
    [ServiceType.RehabilitationTherapy]: "Terapia de Rehabilitación",
    [ServiceType.LabStudies]: "Estudios de Laboratorio",
    [ServiceType.Medicines]: "Medicinas",
    [ServiceType.MedicalEquipment]: "Equipo Médico",
    [ServiceType.HomeNurse]: "Enfermería a Domicilio",
    [ServiceType.ProstheticsAndMechanicalEquipment]: "Prótesis y equipos mecánicos",
    [ServiceType.Ambulance]: "Ambulancia",
    [ServiceType.InPersonConsult]: "Consulta en Persona",
    [ServiceType.OnDemandConsult]: "Videoconsulta",
    [ServiceType.PharmacyOrder]: "Orden de farmacia",
    [ServiceType.ExtVideoConsult]: "Consulta externa",
    [ServiceType.Urgency]: "Urgencia",
    [ServiceType.ExternalConsult]: "Especialista",
    [ServiceType.SurgicalMaterials]: "Materiales quirúrgicos",
    [ServiceType.IncludedConsult]: "Consulta incluida",
    [ServiceType.InPersonConsultByProvider]: "Consulta en Persona por proveedor",
    [ServiceType.ChatConsult]: "Chat consulta",
    [ServiceType.MembershipPsychologyConsult]: "Consulta de psicología",
  },
  satRegime: {
    [SatRegime.LegalEntity]: "Entidad legal - 601",
    [SatRegime.NaturalPerson]: "Persona física con actividad empresarial - 612",
    [SatRegime.GenericEntity]: "Entidad generica - 616",
    [SatRegime.NaturalPersonSimplifiedTaxRegime]: "Regimen simplificado - 626",
    [SatRegime.NaturalPersonSalariesAndSimilarsIncome]: "Sueldos y Salarios e Ingresos Asimilados a Salarios - 605",
    [SatRegime.NaturalPersonTaxIncorporation]: "Incorporación Fiscal - 621",
    [SatRegime.NaturalPersonWithTechPlatformIncome]: "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas - 625",
    [SatRegime.NaturalPersonForeignResidence]: "Residentes en el Extranjero sin Establecimiento Permanente en México - 610",
    [SatRegime.LegalEntityNonLucrative]: "Personas Morales con Fines no Lucrativos - 603",
    [SatRegime.Lease]: "Arrendamiento - 606",
    [SatRegime.GoodsAcquisition]: "Régimen de Enajenación o Adquisición de Bienes - 607",
    [SatRegime.OtherIncome]: "Demás ingresos - 608",
    [SatRegime.StockIncome]: "Ingresos por Dividendos (socios y accionistas) - 611",
    [SatRegime.InterestIncome]: "Ingresos por intereses - 614",
    [SatRegime.PrizeIncome]: "Régimen de los ingresos por obtención de premios - 615",
    [SatRegime.DeferredIncome]: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos - 620",
    [SatRegime.AgriculturalLivestockForestryAndFishingActivities]: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras - 622",
    [SatRegime.OptionalForSocietyGroups]: "Opcional para Grupos de Sociedades - 623",
    [SatRegime.Coordinated]: "Coordinados - 624",
    [SatRegime.Hidrocarburates]: "Hidrocarburos - 628",
    [SatRegime.Consolidation]: "Consolidación - 609",
    [SatRegime.MultinationalBusinesses]: "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales - 629",
    [SatRegime.StockIncomeTrading]: "Enajenación de acciones en bolsa de valores - 630",
  },
  satInvoiceUseType: {
    [SatInvoiceUseType.GeneralExpenses]: "Gastos generales - G03",
    [SatInvoiceUseType.MedicalDentistryAndHospitalServices]: "Servicios médicos o dentales - D01",
    [SatInvoiceUseType.MedicalExpensesInsurance]: "Gastos por seguro médico - D07",
    [SatInvoiceUseType.NoTaxApplicable]: "Sin efectos fiscales - S01",
    [SatInvoiceUseType.PaymentsUseCase]: "Pagos - CP01",
    [SatInvoiceUseType.ToBeDefined]: "Por definir (Similar a sin efectos fiscales para 3.3) - P01",
    [SatInvoiceUseType.Unknown]: "Desconocido",
  },
  businessGroupsConfigState: {
    [BusinessGroupsConfigState.Active]: "Activa",
    [BusinessGroupsConfigState.Inactive]: "Inactiva",
    [BusinessGroupsConfigState.Locked]: "Bloqueada",
    [BusinessGroupsConfigState.PendingActivation]: "En espera de activación",
  },
  businessBeneficiaryState: {
    [BusinessBeneficiaryState.Active]: "Activo",
    [BusinessBeneficiaryState.PendingActivation]: "En espera de activación",
    [BusinessBeneficiaryState.Inactive]: "Inactivo",
    [BusinessBeneficiaryState.Rejected]: "Rechazado",
    [BusinessBeneficiaryState.Deactivated]: "Desactivado",
  },
  BusinessBeneficiaryConfigProposalState: {
    [BusinessBeneficiaryConfigProposalState.Applied]: "Aplicada",
    [BusinessBeneficiaryConfigProposalState.Pending]: "Pendiente",
    [BusinessBeneficiaryConfigProposalState.Cancelled]: "Cancelada",
  },

  ScheduledConsultKind: {
    FIRST_TIME: "Primera vez",
    FOLLOW_UP: "Seguimiento",
  },

  onDemandConsultClassificationType: {
    [OnDemandConsultClassificationType.NotApplicable]: "No aplicable",
    [OnDemandConsultClassificationType.Welcome]: "Preventiva bienvenida",
    [OnDemandConsultClassificationType.AnnualCheckup]: "Check up anual",
    [OnDemandConsultClassificationType.PrimaryCare]: "Dr de Cabecera",
    [OnDemandConsultClassificationType.NextAvailable]: "Siguiente doctor disponible",
    [OnDemandConsultClassificationType.OnDemand]: "On demand",
    [OnDemandConsultClassificationType.FirstTime]: "Primera Vez",
    [OnDemandConsultClassificationType.FollowUp]: "Seguimiento",
    [OnDemandConsultClassificationType.NightCall]: "Llamada nocturna",
  },

  AppointmentUrgency: {
    ASAP: "Lo más pronto posible",
    NEXT_WEEK: "Próxima semana",
    NEXT_MONTH: "Próximo mes",
    OTHER: "Otro",
  },

  AppointmentDayWindow: {
    WEEKDAY: "Entre semana",
    WEEK_END: "Fin de semana",
    OTHER: "Otro",
  },

  AppointmentTimeWindow: {
    MORNING: "Por la mañana",
    AFTERNOON: "Por la tarde",
    OTHER: "Otro",
  },
  BusinessAdminRole: {
    [BusinessAdminRole.Owner]: "Super Admin",
    [BusinessAdminRole.Admin]: "Admin",
    [BusinessAdminRole.FinanceUser]: "Admin financiero",
  },
  BusinessState: {
    [BusinessState.Draft]: "Draft",
    [BusinessState.Approved]: "Aprobada",
    [BusinessState.Activated]: "Activada",
    [BusinessState.Revoked]: "Revocada",
    [BusinessState.ComplianceReview]: "En revisión",
    [BusinessState.ContractSent]: "Contrado enviado",
    [BusinessState.ContractSigned]: "Contrado firmado",
    [BusinessState.CorrectingInfo]: "Corregir información",
    [BusinessState.PortalActivated]: "Portal activado",
    [BusinessState.UploadingInfo]: "Subiendo información",
    [BusinessState.ConfigsConfirmed]: "Configuraciones confirmadas",
    [BusinessState.DocumentsReviewComplete]: "Revisión de documentos completada",
  },
  BusinessPreApprovedStatesType: {
    [BusinessPreApprovedStatesType.Draft]: "Draft",
    [BusinessPreApprovedStatesType.ComplianceReview]: "En revisión",
    [BusinessPreApprovedStatesType.ContractSent]: "Contrado enviado",
    [BusinessPreApprovedStatesType.ContractSigned]: "Contrado firmado",
    [BusinessPreApprovedStatesType.CorrectingInfo]: "Corregir información",
    [BusinessPreApprovedStatesType.PortalActivated]: "Portal activado",
    [BusinessPreApprovedStatesType.UploadingInfo]: "Subiendo información",
    [BusinessPreApprovedStatesType.ConfigsConfirmed]: "Configuraciones confirmadas",
    [BusinessPreApprovedStatesType.DocumentsReviewComplete]: "Revisión de documentos completada",
  },
  BusinessConfigProposalIssuanceType: {
    [BusinessConfigProposalIssuanceType.Initial]: "Inicial",
    [BusinessConfigProposalIssuanceType.Renewal]: "Renovación",
    [BusinessConfigProposalIssuanceType.GlobalConfigurationChanges]: "Configuración global",
    [BusinessConfigProposalIssuanceType.MaternityActivation]: "Activación C.Materna",
    [BusinessConfigProposalIssuanceType.MaternityCancellation]: "Cancelación C.Materna",
    [BusinessConfigProposalIssuanceType.MaternityWaitingPeriodUpdate]: "Tiempo de espera C.Materna",
    [BusinessConfigProposalIssuanceType.SingleConfigurationChanges]: "Configuración individual",
    [BusinessConfigProposalIssuanceType.GroupMigration]: "Migración de grupo",
    [BusinessConfigProposalIssuanceType.GroupChanges]: "Cambio de grupo",
    [BusinessConfigProposalIssuanceType.MigrationToCollective]: "Migración a colectivo",
    [BusinessConfigProposalIssuanceType.MigrationToBundle]: "Migración a bundle",
    [BusinessConfigProposalIssuanceType.Reactivation]: "Reactivación",
  },
  BusinessConfigProposalState: {
    [BusinessConfigProposalState.Draft]: "Draft",
    [BusinessConfigProposalState.Negotiating]: "Negotiating",
    [BusinessConfigProposalState.Approved]: "Approved",
    [BusinessConfigProposalState.CancellingHealthPlans]: "Cancelling Health Plans",
    [BusinessConfigProposalState.HealthPlansCancelled]: "Health Plans Cancelled",
    [BusinessConfigProposalState.Activated]: "Activated",
    [BusinessConfigProposalState.CreatingApplications]: "Creating Applications",
    [BusinessConfigProposalState.CreatingApplicationsFailed]: "Creating Applications Failed",
    [BusinessConfigProposalState.ApplicationsCreated]: "Applications Created",
    [BusinessConfigProposalState.PartiallyApplied]: "Partially Applied",
    [BusinessConfigProposalState.FullyApplied]: "Fully Applied",
    [BusinessConfigProposalState.Rejected]: "Rejected",
  },
  BusinessOrdinaryPeriodDays: {
    [BusinessOrdinaryPeriodDays.PeriodDays_15]: "15 días",
    [BusinessOrdinaryPeriodDays.PeriodDays_30]: "30 días",
    [BusinessOrdinaryPeriodDays.PeriodDays_60]: "60 días",
  },
  automaticChecks: {
    CheckGracePeriod: "El plan del socio está en periodo de gracia",
    CheckHealthPlanIsActive: "El plan del socio está inactivo",
    CheckInvoiceItemAmountsMatchPreapprovedAmounts: "El monto del recibo no cuadra con el último monto preaprobado",
    CheckUserHasClabe: "El socio no tiene registrada una cuenta CLABE",
    CheckUsageRate: "El socio ha estado recibiendo muchos pagos últimamente",
    CheckHasCorrectRFC: "Factura evidencia no tiene RFC del socio o de Sofia",
    CheckUniqueService: "El servicio puede estar duplicado",
    CheckRepeatedEvidenceUUID: "Factura evidencia repetida en otro servicio",
    CheckHasComments: "Invoice tiene comentarios",
    CheckHasLabPrescription: "El servicio no está relacionado a ninguna receta con estudios",
    CheckHasMedicinePrescription: "El servicio no está relacionado a ninguna receta de medicamentos",
    CheckHasAvailableBalance: "El plan no cuenta con saldo disponible para este servicio",
    CheckHealthPlanTerm: "La fecha de ocurrencia no está dentro del periodo de validez del plan",
    CheckOnlyOneActiveHealthPlan: "El socio tiene más de un plan activo",
    CheckIsPurpleProduct: "El producto no es Producto Morado",
    CheckMemberExclusions: "El socio tiene exclusiones",
    CheckMemberPreExistences: "El socio tiene pre existencias",
    CheckServiceMaxAmount: "El monto del recibo rebasa el límite para dictaminación automática",
    CheckServiceIsPrimaryCare: "El servicio no es de un doctor de cabecera",
    CheckEVItemsMatchLabsPrescriptionItems: "El recibo no tiene el mismo número de estudios de laboratorio que la receta",
    CheckEVItemsMatchMedsPrescriptionItems: "El recibo no tiene el mismo número de items de medicinas que la receta",
    CheckServiceOccurrenceAndRelatedConsultDate: "La fecha de ocurrencia del servicio y la consulta difiere en más de 30 días",
    CheckServiceNetworkProvider: "El proveedor del servicio está fuera de la red",
    CheckEVItemsRejectedHistory: "Un medicamento del servicio ha sido rechazado en dictaminaciones pasadas",
    CheckElectronicVouchersWithoutRefundAreIssued: "No todas las facturas de cancelación han sido emitidas",
    CheckHealthPlanIsNotOwnedByBusiness: "Un plan de salid relacionado a los objetos de pago pertenece a una empresa",
    CheckUserHasPaidAllTheirInvoices: "El usuario no ha pagado todos sus recibos",
    CheckCancellationAmendmentsInvoiceItemsMatchAmendmentTotal: "El total de los montos en algún endosos no coincide con el total caclulado.",
    CheckIsNotOriginCancellation: "No podemos pagar recibos que corresponden a una cancelación de origen",
    CheckElectronicVouchersWithRefundAreIssued: "No todas las facturas que corresponden a los pagos de cancelación han sido emitidas",
    CheckHealthPlanOwnerHasNoActiveDisputes: "El usuario tiene alguna disputa activa",
    CheckOwnerUserIsNotGettingPaidMoreThanWhatTheyPaid: "Este recibo intenta pagar más de lo cobrado al usuario.",
    CheckElectronicVoucherAmount: "El monto del servicio es superior al monto de la factura relacionada.",
    CheckDoctorMonthlyPayedAmountBellowLimit: "La cantidad pagada al doctor durante el mes en curso es mayor a la permitida para pago automático",
    CheckIsValidServiceForAutomaticMedicalFeesDoctorPayment: "El servicio no es válido para el pago automático del doctor",
    CheckIsValidDoctorSpecialtyForProcedure: "La especialidad del doctor no es válida para pago automático del procedimiento en consultorio",
    CheckDoctorUsageRate: "El doctor rebasa el número de procedimientos en consultorio al mes para pago automático",
    CheckMemberAccumulatedProceduresBellowLimit: "El socio rebasa el número de procedimientos al año para pago automático",
    CheckPayoutIsDirectPayment: "La forma de pago no es pago directo",
    CheckServiceAmountBellowCPTLimit: "La cantidad a pagar rebasa el límite por CPT",
    CheckHasEvidence: "El servicio no cuenta con evidencias",
    CheckCieWaitingPeriod: "No se cumple el periodo de espera para el diagnóstico",
    CheckHealthPlanValidityLimits: "El plan del socio en los límites de su periodo de validez",
    CheckMedsCieMatch: "Los medicamentos no parecen corresponder a tratamientos anteriores para los CIEs del servicio",
  },
  doctorLeaveReasons: {
    [DoctorLeaveReason.Recovery]: "Incapacidad",
    [DoctorLeaveReason.Maternity]: "Maternidad",
    [DoctorLeaveReason.Paternity]: "Paternidad",
    [DoctorLeaveReason.Vacationing]: "Vacaciones",
  },
  MaternityWaitingPeriodMonths: {
    [MaternityWaitingPeriodMonths.NoWaitingTime]: "0 meses",
    [MaternityWaitingPeriodMonths.SixMonths]: "6 meses",
  },
  PersonCategory: {
    [PersonCategory.Legal]: "Persona moral",
    [PersonCategory.Natural]: "Persona física",
  },
  CaseEventFinalLetterLetterType: {
    [CaseEventFinalLetterLetterType.DoctorFinalLetter]: "Carta Final Doctor",
    [CaseEventFinalLetterLetterType.HospitalFinalLetter]: "Carta Final Hospital",
    [CaseEventFinalLetterLetterType.MemberFinalLetter]: "Carta Final Soci@",
  },
  CaseEventRejectionLetterType: {
    [CaseEventRejectionLetterLetterType.Doctor]: "Doctor: Carta de rechazo",
    [CaseEventRejectionLetterLetterType.Hospital]: "Hospital: Carta de rechazo",
    [CaseEventRejectionLetterLetterType.Member]: "Soci@: Carta de rechazo",
  },
  MedicalFeeFinalLetterType: {
    [MedicalFeeFinalLetterLetterType.Doctor]: "Carta Final Doctor",
    [MedicalFeeFinalLetterLetterType.Member]: "Carta Final Soci@",
  },
  MedicalFeeRejectionLetterType: {
    [MedicalFeeRejectionLetterLetterType.Doctor]: "Doctor: Carta de rechazo",
    [MedicalFeeRejectionLetterLetterType.Member]: "Soci@: Carta de rechazo",
  },
  CaseEventStage: {
    [CaseEventStage.PreAdmission]: "Pre-Admisión",
    [CaseEventStage.Hospitalized]: "Hospitalizado",
    [CaseEventStage.Discharged]: "Alta",
  },
  CaseEventState: {
    [CaseEventState.Created]: "Creado",
    [CaseEventState.InAdministrativeRuling]: "Dictaminación administrativa",
    [CaseEventState.InMedicalPreRuling]: "Pre-dictaminación médica",
    [CaseEventState.Accepted]: "Aceptado",
    [CaseEventState.Rejected]: "Rechazado",
    [CaseEventState.Cancelled]: "Cancelado",
    [CaseEventState.Quoting]: "Cotizando",
    [CaseEventState.QuoteChosen]: "Cotización elegida",
    [CaseEventState.LettersSent]: "Cartas Enviadas",
    [CaseEventState.Scheduled]: "Programado",
    [CaseEventState.Active]: "Activo",
    [CaseEventState.DischargeInitiated]: "Alta iniciada",
    [CaseEventState.DischargeCompleted]: "Alta Completada",
    [CaseEventState.Paused]: "Pausado",
    [CaseEventState.MedicalMonitoring]: "Monitoreo médico",
    [CaseEventState.ProcessFinalized]: "Proceso finalizado",
    [CaseEventState.PaymentsPending]: "Pagos pendientes",
  },
  CaseEventSummaryCategory: {
    [CaseEventSummaryCategory.ProviderResponse]: "Respuesta de proveedor(es)",
    [CaseEventSummaryCategory.AttentionDuringHospitalization]: "Atención durante hospitalización",
    [CaseEventSummaryCategory.Quote]: "Cotización",
    [CaseEventSummaryCategory.DoctorsFromSofiamed]: "Doctores de red",
    [CaseEventSummaryCategory.DoctorsFriends]: "Doctores amigos",
    [CaseEventSummaryCategory.MedicalFollowup]: "Seguimiento médico",
    [CaseEventSummaryCategory.Payments]: "Cobros",
    [CaseEventSummaryCategory.Diagnosis]: "Diagnóstico",
    [CaseEventSummaryCategory.AttentionFromSofia]: "Atención Sofía",
    [CaseEventSummaryCategory.HospitalAccount]: "Cuenta hospitalaria.",
  },
  SpecializationType: {
    [SpecializationType.Specialization]: "Especialidad",
    [SpecializationType.SubSpecialization]: "Subespecialidad",
    [SpecializationType.HighSpecialization]: "Alta Especialidad",
    [SpecializationType.Master]: "Maestría",
    [SpecializationType.Fellowship]: "Fellowship",
    [SpecializationType.Diplomat]: "Diplomado",
    [SpecializationType.Bachelor]: "Licenciatura",
    [SpecializationType.Doctorate]: "Doctorado",
  },
  DoctorLeadState: {
    [DoctorLeadState.Created]: "Creado",
    [DoctorLeadState.Approved]: "Aprobado",
    [DoctorLeadState.Rejected]: "Rechazado",
    [DoctorLeadState.DoctorCreated]: "Contraseña creada",
  },
  DoctorLeadLostReason: {
    [DoctorLeadLostReason.UnrequiredSpecialty]: "No es una especialidad que buscamos",
    [DoctorLeadLostReason.InvalidAgeRange]: "No está en el rango de edad",
    [DoctorLeadLostReason.UnrequiredForCity]: "No buscamos esta especialidad en esta ciudad",
    [DoctorLeadLostReason.NoSpeciality]: "No tiene especialidad",
    [DoctorLeadLostReason.NotADoctor]: "No es médico",
    [DoctorLeadLostReason.NoCertification]: "No tiene certificación del consejo",
    [DoctorLeadLostReason.NoExperience]: "No tiene experiencia en práctica privada",
    [DoctorLeadLostReason.LimitedAcademicReqsForMeds]: "No cumple con requisitos académicos (medicina general)",
    [DoctorLeadLostReason.LimitedAcademicReqsForSpecialists]: "No cumple con requisitos académicos (especialidad)",
    [DoctorLeadLostReason.SufficientSimilarSpecialists]: "Ya tenemos muchos drs de esta especialidad",
    [DoctorLeadLostReason.DoesNotMeetAcademicProfile]: "No cumple con perfil académico",
    [DoctorLeadLostReason.DoctorNotInterested]: "No le interesa",
    [DoctorLeadLostReason.DoctorNotReachable]: "No localizado",
    [DoctorLeadLostReason.HigherFee]: "Tarifa más elevada a la de Sofía",
  },
  DoctorLeadInfoState: {
    [DoctorLeadInfoState.PersonalData]: "Contraseña creada",
    [DoctorLeadInfoState.AcademicInfo]: "Personal info completed",
    [DoctorLeadInfoState.PaymentData]: "Academic info completed",
    [DoctorLeadInfoState.Addresses]: "Payment info completed",
    [DoctorLeadInfoState.DataCompleted]: "Pendiente revision y aprobacion para contrato",
    [DoctorLeadInfoState.Reviewed]: "Revisado",
    [DoctorLeadInfoState.ContractSent]: "Contrato enviado",
    [DoctorLeadInfoState.Active]: "Doctor activo",
  },
  ProfilingDataState: {
    [ProfilingDataState.Draft]: "Borrador",
    [ProfilingDataState.Approved]: "Aprobado",
    [ProfilingDataState.Cancelled]: "Cancelado",
    [ProfilingDataState.MoreInfoNeeded]: "Falta información",
    [ProfilingDataState.Rejected]: "Rechazado",
    [ProfilingDataState.ReviewNeeded]: "Pendiente de revisión",
  },
  ScheduledApprovalCallStatus: {
    [ScheduledApprovalCallStatus.Pending]: "Pendiente de agendar",
    [ScheduledApprovalCallStatus.Scheduled]: "Agendada",
    [ScheduledApprovalCallStatus.Cancelled]: "Cancelada",
    [ScheduledApprovalCallStatus.Finished]: "Finalizada",
    [ScheduledApprovalCallStatus.Rescheduled]: "Reagendada",
    [ScheduledApprovalCallStatus.NoShow]: "Sin respuesta",
  },
  ProfilingHospitalizationType: {
    [ProfilingHospitalizationType.Urgency]: "Urgencia",
    [ProfilingHospitalizationType.Other]: "Otro",
    [ProfilingHospitalizationType.SurgeryOrProcedure]: "Cirugía o procedimiento",
  },
  ProfilingHospitalizationSideLocationType: {
    [ProfilingHospitalizationSideLocationType.Left]: "Izquierda",
    [ProfilingHospitalizationSideLocationType.Right]: "Derecha",
    [ProfilingHospitalizationSideLocationType.Bilateral]: "Bilateral",
    [ProfilingHospitalizationSideLocationType.NotApplicable]: "No aplica",
  },
  ProfilingMentalDiagnosis: {
    [ProfilingMentalDiagnosis.Anxiety]: "Ansiedad",
    [ProfilingMentalDiagnosis.Depression]: "Depresión",
  },
  ProfilingAilment: {
    [ProfilingAilment.Myopia]: "Miopía",
    [ProfilingAilment.Astigmatism]: "Astigmatismo",
    [ProfilingAilment.Acne]: "Acné",
    [ProfilingAilment.Migraine]: "Migraña",
    [ProfilingAilment.Asthma]: "Asma",
    [ProfilingAilment.Gastritis]: "Gastritis",
    [ProfilingAilment.Reflux]: "Reflujo",
    [ProfilingAilment.Rhinitis]: "Rinitis",
    [ProfilingAilment.Dermatitis]: "Dermatitis",
  },
  ProfilingVisionConditionType: {
    [ProfilingVisionConditionType.Myopia]: "Miopía",
    [ProfilingVisionConditionType.Astigmatism]: "Astigmatismo",
    [ProfilingVisionConditionType.Farsightedness]: "Hipermetropía",
  },
  ProfilingGastrointestinalConditionType: {
    [ProfilingGastrointestinalConditionType.Reflux]: "Reflujo",
    [ProfilingGastrointestinalConditionType.Gastritis]: "Gastritis",
  },
  MemberExclusionState: {
    [MemberExclusionState.Confirmed]: "Preexistencia - Confirmada",
    [MemberExclusionState.Exempted]: "Preexistencia - Excepción",
    [MemberExclusionState.Potential]: "Preexistencia - Potencial",
    [MemberExclusionState.RuledOut]: "Preexistencia - Descartada",
    [MemberExclusionState.UnderReview]: "Preexistencia - En investigación",
    [MemberExclusionState.Voided]: "Preexistencia y Tiempo de espera - Periodo cumplido",
    [MemberExclusionState.WaitingPeriodCoverage]: "Tiempo de espera - Rechazo temporal",
    [MemberExclusionState.WaitingPeriodNoCoverage]: "Tiempo de espera - Rechazo definitivo",
  },
  MemberExclusionVoidedMonths: {
    SIX: "6 meses",
    TWELVE: " 12 meses",
    EIGHTEEN: "18 meses",
    TWENTY_FOUR: "24 meses",
    FORTY_EIGHT: "48 meses",
  },
  ProviderStates: {
    ACTIVE: "Activo",
    PENDING: "Pendiente",
    INACTIVE: "Inactivo",
  },
  ProviderContractCategory: {
    SERVICES: "Servicios",
    CASH_AGREEMENT: "Contado",
    CREDIT_AGREEMENT: "Convenio",
  },
  ProviderTier: {
    REGULAR: "Regular",
    PREMIUM: "Premium",
  },
  DoctorPriorityLevel: {
    [DoctorPriorityLevel.Lvl_1]: "Nivel 1",
    [DoctorPriorityLevel.Lvl_2]: "Nivel 2",
    [DoctorPriorityLevel.Lvl_3]: "Nivel 3",
  },
  CancellationReason: {
    [ConsultCancellationReason.AdministrativeQuestion]: "Problemas administrativos",
    [ConsultCancellationReason.CancelledByMember]: "El/la socio/a canceló la cita",
    [ConsultCancellationReason.DoctorUrgency]: "Tuve que cancelar la cita o no pude dar la consulta",
    [ConsultCancellationReason.MemberBadInternet]: "Mala señal de internet",
    [ConsultCancellationReason.MemberInadequateLocation]: "Ubicación incorrecta del socio",
    [ConsultCancellationReason.MemberInsufficientTime]: "No se concretó la cita por conflictos de agenda",
    [ConsultCancellationReason.MemberNoShow]: "El/la socio/a no se presentó a la consulta",
    [ConsultCancellationReason.NoMemberResponse]: "No se concretó la cita por falta de respuesta de/la socio/a",
    [ConsultCancellationReason.NonReferenceMedical]: "La cita requiere una referencia médica previa",
    [ConsultCancellationReason.Other]: "Otro",
    [ConsultCancellationReason.SpecificMedicalQuestion]: "Situación medica especifica",
    [ConsultCancellationReason.StuckConsultCard]: "Fallas técnicas del sistema",
  },
  CptProviderClassification: {
    [CptProviderClassification.TreatingDoctor]: "Médico Tratante",
    [CptProviderClassification.Anesthesiologist]: "Anestesiólogo",
    [CptProviderClassification.DoctorFirstAssistant]: "Primer ayudante",
    [CptProviderClassification.DoctorSecondAssistant]: "Segundo ayudante",
    [CptProviderClassification.Hospital]: "Hospital",
    [CptProviderClassification.Other]: "Otro",
  },
  CptPriceSource: {
    [CptPriceSource.CaobaAverage_25_50]: "Promedio Caoba 25 50",
    [CptPriceSource.Sofia_2023]: "Sofia 2023",
  },
  CoveragePayoutState: {
    [ServiceCoveragePayoutState.Draft]: "Draft",
    [ServiceCoveragePayoutState.Requested]: "Solicitado",
    [ServiceCoveragePayoutState.Failed]: "Fallido",
    [ServiceCoveragePayoutState.Confirmed]: "Confirmado",
    [ServiceCoveragePayoutState.InSitu]: "In situ",
    [ServiceCoveragePayoutState.Uncollectible]: "Incobrable",
    [ServiceCoveragePayoutState.Refunded]: "Reembolsado",
    [ServiceCoveragePayoutState.Staging]: "Procesando",
  },
  ServiceCoverageTransactionState: {
    [ServiceTransactionState.Draft]: "Draft",
    [ServiceTransactionState.Requested]: "Solicitado",
    [ServiceTransactionState.Failed]: "Fallido",
    [ServiceTransactionState.Confirmed]: "Confirmado",
    [ServiceTransactionState.InSitu]: "In situ",
    [ServiceTransactionState.Uncollectible]: "Incobrable",
    [ServiceTransactionState.Refunded]: "Reembolsado",
    [ServiceTransactionState.Staging]: "Procesando",
  },
  CeleryTaskStatusType: {
    [CeleryTaskStatusType.Pending]: "Pendiente",
    [CeleryTaskStatusType.Success]: "OK",
    [CeleryTaskStatusType.Failure]: "Error",
  },
  BusinessBeneficiaryCategory: {
    [BusinessBeneficiaryCategory.Employee]: "Emplead@",
    [BusinessBeneficiaryCategory.FamilyMember]: "Familiar",
  },
  MedicalTeamRole: {
    [MedicalTeamRole.Anesthesiologist]: "Anestesiólogo",
    [MedicalTeamRole.ConsultingPhysician]: "Interconsultante",
    [MedicalTeamRole.FirstHelper]: "Primer ayudante",
    [MedicalTeamRole.SecondHelper]: "Segundo ayudante",
    [MedicalTeamRole.Surgeon]: "Cirujano",
    [MedicalTeamRole.TreatingPhysician]: "Médico tratante",
  },
  CieCatalogCategory: {
    [CieCatalogCategory.Acute]: "Agudo",
    [CieCatalogCategory.Chronic]: "Crónico",
    [CieCatalogCategory.Undefined]: "Indefinido",
  },
  ServiceCancelReason: {
    [ServiceCancelReason.Duplicated]: "Servicio duplicado",
    [ServiceCancelReason.MemberCancellation]: "Cancelación por Soci@",
    [ServiceCancelReason.MissingEvidences]: "Falta de evidencias",
    [ServiceCancelReason.MultipleElectronicVoucher]: "Servicio con más de una factura",
    [ServiceCancelReason.Other]: "Otra",
  },
  PaymentMethodSelected: {
    [CaseEventPaymentMethodSelected.CreditCard]: "Tarjeta de crédito",
    [CaseEventPaymentMethodSelected.InSitu]: "Pago in situ",
  },
  ServiceEscalationReason: {
    [ServiceEscalationReason.Evidences]: "Evidencias",
    [ServiceEscalationReason.Other]: "Otra",
    [ServiceEscalationReason.Preexistence]: "Preexistencia",
  },
  BusinessPaymentConfigState: {
    [BusinessPaymentStatus.Ok]: "Sin pago pendiente",
    [BusinessPaymentStatus.OkOrdinary]: "Periodo ordinario",
    [BusinessPaymentStatus.OverdueExtension]: "Periodo de gracia: extensión",
    [BusinessPaymentStatus.OverdueExtemporary]: "Periodo gracia: prórroga",
    [BusinessPaymentStatus.OverduePastDeadline]: "Deudor por prima",
  },
  BusinessPaymentMedicalService: {
    [BusinessPaymentMedicalService.DirectPayment]: "Pago directo",
    [BusinessPaymentMedicalService.RefundPayment]: "Por reembolso por deuda",
  },
} as const;

export default translations;
